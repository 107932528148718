<template>
  <div>
    <Error v-bind:errors="errors" />
    <div class="centerOfRow" v-if="errors.length > 0">
      <button @click="scanAgain">Scan again</button>
    </div>
    <Loading v-bind:showLoadScreen="screenLoading" />

    <div class="line"
      v-if="$store.state.sytUser.type === 'PATRON' && $store.state.sytUser.operatingAsVendor.length < 1">
      <Border>
        <div class="item">
          <div class="lineItemHeader">Only Vendors can scan codes.</div>
          <div class="lineItem">
            You can easily change to a vendor in the 'Admin' section. This will
            allow you to setup reward programmes, track progress and scan reward vouchers.
          </div>
        </div>
        <div class="item">
          <div class="lineItem" @click="gotoAdmin()">
            You can setup any reward programme - even one to track and reward your
            kids for doing their homework. Click here to switch to a Vendor account or go to the 'Admin' section and
            select 'User management'.
            <img src="../assets/select.png" class="imgIcon right smaller" />
          </div>
        </div>
      </Border>
    </div>

    <div
      v-if="showScanner && $store.state.sytUser.type === 'VENDOR' && $store.state.sytUser.operatingAsVendor.length < 1">
      <Border>
        <div class="item">
          <div class="lineItemHeader">
            Scanning on behalf of {{ $store.state.sytUser.vendorName }}
          </div>
        </div>
        <div class="qrScannerContainer">
          <div class="qrScannerSize">
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
          </div>
        </div>
      </Border>
    </div>


    <div v-if="showScanner && $store.state.sytUser.operatingAsVendor.length > 1">
      <!-- need to select who you are operating as -->
      <div v-if="showScanner && $store.state.sytUser.type === 'VENDOR'">
        <div v-if="!operatingAsAnotherVendor && !operatingAsYourSelf">
          <Border>
            <div @click="chooseYourself()">
              <div class="item">
                <div class="lineItemHeader">
                  Scan on behalf of {{ $store.state.sytUser.vendorName }}
                  <img src="../assets/select.png" class="imgIcon right smaller" />
                </div>
              </div>
            </div>
          </Border>
        </div>
        <div v-if="!operatingAsYourSelf && !operatingAsAnotherVendor">
          <Border>
            <div @click="chooseVendor()">
              <div class="item">
                <div class="lineItemHeader">
                  Scan on behalf of {{ actingAsVendor.vendorName }}
                  <img src="../assets/select.png" class="imgIcon right smaller" />
                </div>
              </div>
            </div>
          </Border>
        </div>
        <!-- operating as yourself         -->
        <div v-if="operatingAsYourSelf">
          <Border>
            <div class="item">
              <div class="lineItemHeader">
                Scanning on behalf of {{ $store.state.sytUser.vendorName }}
              </div>
            </div>
            <div class="qrScannerContainer">
              <div class="qrScannerSize">
                <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
              </div>
            </div>

            <div class="right">
              <button @click="cancel">Cancel</button>
            </div>
          </Border>
        </div>
        <!-- operating as another vendor -->
        <div v-if="operatingAsAnotherVendor">
          <Border>
            <div class="item">
              <div class="lineItemHeader">
                Scanning on behalf of {{ actingAsVendor.vendorName }}
              </div>
            </div>
            <div class="qrScannerContainer">
              <div class="qrScannerSize">
                <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
              </div>
            </div>

            <div class="right">
              <button @click="cancel">Cancel</button>
            </div>
          </Border>
        </div>
      </div>
      <div v-if="showScanner && $store.state.sytUser.type === 'PATRON'">
        <!-- Show who you are operating as -->
        <Border>
          <div class="item">
            <div class="lineItemHeader">
              Scan on behalf of{{ actingAsVendor.vendorName }}
            </div>
          </div>
          <div class="qrScannerContainer">
            <div class="qrScannerSize">
              <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
            </div>
          </div>

          <div class="right">
            <button @click="cancel">Cancel</button>
          </div>
        </Border>
      </div>
    </div>

    <div v-if="$store.state.sytUser.type === 'VENDOR'">
      <div class="right">
        <button @click="scanAgain" v-if="showScanAgain">Scan again</button>
      </div>
      <Border v-bind:showLoading="checkUser" v-if="!allocateDone && qrType === 'n'">
        <div v-if="!checkUser && rewards.length > 0">
          <div class="right">
            <button @click="scanAgain">Scan again</button>
          </div>
          <div class="lineItem item3">
            Select a reward to allocate 1 reward point
          </div>
          <div v-for="rewardInLoop in rewards" v-bind:key="rewardInLoop.rewardId"
            @click="loadReward(rewardInLoop.rewardId)">
            <div class="item">
              <div class="lineItemHeader">
                ~ {{ rewardInLoop.rewardName }} reward ~
                <img src="../assets/select.png" class="imgIcon right smaller" />
              </div>
              <div class="lineItem">
                {{ rewardInLoop.actualReward }} after
                {{ rewardInLoop.redeemAmount }} purchases
              </div>
              <div class="lineItem">
                Valid from {{ rewardInLoop.startDate }}
              </div>
              <div class="lineItem">to {{ rewardInLoop.endDate }}</div>

              <div v-if="reward && reward.rewardId == rewardInLoop.rewardId">
                <div class="lineItem">Allocate 1 reward point?</div>
                <div class="centerOfRow">
                  <button @click="allocateConfirmed">Yes</button>
                  <button @click="allocateCancelled">No</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!checkUser && rewards.length < 1">
          <div class="item">
            <div class="lineItem" @click="gotoRewards()">
              <img src="../assets/select.png" class="imgIcon right smaller" />
              {{ actingAsVendor.vendorName }} have no active rewards setup to allocate. The administrator of {{
      actingAsVendor.vendorName }} can click
              here to create rewards or go to the 'Admin' section and select 'Create and change rewards'.
            </div>
          </div>
        </div>

        <div v-if="checkUser"><br />Checking qr code...</div>
      </Border>

      <Border v-if="!allocateDone &&
      voucher.voucherId &&
      voucher.voucherId.length > 0 &&
      qrType === 'v' &&
      voucher.status === 'VALID'
      ">
        <div class="item">
          <div class="lineItem">
            Scanned voucher for {{ voucher.rewardName }} reward is valid.
          </div>
          <div class="lineItem">
            Dispence {{ voucher.actualReward }} to scanned patron?
          </div>
          <div class="centerOfRow">
            <button @click="voucherConfirmed">Yes</button>
            <button @click="voucherCancelled">No</button>
          </div>
        </div>
      </Border>

      <Border v-if="!allocateDone &&
      qrType === 'v' &&
      (voucher.status === 'REDEEMED' || voucher.status === 'EXPIRED')
      ">
        <div class="item">
          <div class="lineItem">Scanned voucher is not valid.</div>
          <div class="lineItem">
            The voucher has a status of {{ voucher.status }}
          </div>
          <div class="lineItem">
            Please ask the person to present a different voucher and scan again
          </div>
          <div class="right">
            <button @click="scanAgain">Scan next</button>
          </div>
        </div>
      </Border>

      <div v-if="allocateDone && qrType === 'n'">
        <Border>
          <div class="item">
            <div class="lineItem item3">Reward allocated</div>
            <div class="lineItem">The reward count of '{{ reward.rewardName }}' was increased</div>
            <div v-if="!showGoalReached" class="lineItem">
              Current reward count for the person is {{ trackReward.count }} and
              the goal is
              {{ trackReward.goal }}
            </div>
          </div>
          <div class="item">
            <div class=" lineItem item3" v-if="showGoalReached">Reward goal reached</div>
            <div v-if="!showGoalReached" class="lineItem">
              If the goal is reached, the person will get a
              {{ reward.actualReward }} as the reward
            </div>
            <div v-if="showGoalReached" class="lineItem">
              This person is eligible for a
              '{{ reward.actualReward }}' reward. A voucher has been generated and is in the 'Claim your rewards'
              section on the home screen.
            </div>
          </div>
        </Border>
        <Border>
          <div class="right">
            <button @click="scanAgain">Scan next</button>
          </div>
        </Border>
      </div>

      <Border v-if="allocateDone && qrType === 'v'">
        <div class="lineItem item3">{{ voucher.rewardName }} voucher redeemed</div>
        <div class="lineItem">
          Voucher redeemed and updated. This voucher cannot be used again.
        </div>
        <div class="right">
          <button @click="scanAgain">Scan next</button>
        </div>
      </Border>
    </div>
  </div>
</template>

<script>
import Border from "@/components/Border.vue";
import Error from "@/components/Error.vue";
import Loading from "@/components/Loading.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import Comms from "../shared";
export default {
  name: "Scan",
  components: {
    Border,
    Error,
    Loading,
    QrcodeStream,
  },
  data: function () {
    return {
      errors: [],
      screenLoading: false,
      operatingAsYourSelf: false,
      operatingAsAnotherVendor: false,
      showScanner: true,
      showGoalReached: false,
      result: "",
      allocateDone: false,
      checksDone: false,
      checkRewards: false,
      checkUser: false,
      showScanAgain: false,
      qrType: "",
      actingAsVendor: {},
      useVendorToGetRewards: {},
      findVendor: {
        userName: "",
        userID: "",
      },
      camera: "auto",
      scannedUser: {
        userName: "",
        userID: "",
      },
      scannedUserComplete: {
        userID: "",
        userName: "",
        name: "",
        picture: "",
        type: "",
        status: "",
        vendorName: "",
        // displayName: ""
      },
      rewards: [],
      reward: {
        rewardId: "",
        vendorId: "",
        rewardName: "",
        redeemAmount: "",
        startDate: "",
        endDate: "",
        actualReward: "",
      },
      trackReward: {
        vendorId: "",
        patronId: "",
        rewardId: "",
        lastQrId: "",
        count: -1,
        goal: -1,
      },
      voucher: {
        voucherId: "",
        trackId: "",
        patronId: "",
        rewardId: "",
        vendorId: "",
        createDate: "",
        redeemDate: "",
        status: "",
        vendorName: "",
        rewardName: "",
        actualReward: "",
        operatingAsVendorID: ""
      },
    };
  },
  computed: {},
  methods: {
    cancel() {
      this.operatingAsYourSelf = false;
      this.operatingAsAnotherVendor = false;
    },
    chooseYourself() {
      this.operatingAsYourSelf = true;
    },
    chooseVendor() {
      this.operatingAsAnotherVendor = true;
    },
    getVendorDelegated() {
      this.findVendor.userID = this.$store.state.sytUser.operatingAsVendor;
      this.findVendor.userName = "Unknown";
      Comms.sendToServer(
        "POST",
        "getUserFromID",
        this.findVendor,
        this.errors
      ).then((response) => {
        // console.log("Delegated vendor response.data" + response.data);
        if (response.status === 200) {
          this.actingAsVendor = JSON.parse(response.data);
        } else {
          // vendor not found. Give error message
        }
      });
    },
    scanAgain() {
      this.errors = [];
      this.qrType = "";
      this.voucher.voucherId = "";
      this.showScanner = true;
      this.allocateDone = false;
      this.reward = null;
      this.trackReward.count = -1;
      this.trackReward.goal = -1;
      this.operatingAsYourSelf = false;
      this.operatingAsAnotherVendor = false;
      this.turnCameraOn();
    },
    voucherConfirmed() {
      Comms.sendToServer(
        "POST",
        "updateScannedVoucher",
        this.voucher,
        this.errors
      ).then((response) => {
        // console.log("voucher response.data" + response.data);
        // console.log("voucher response.status" + response.status);
        if (response.status == 204) {
          this.allocateDone = true;
        }
      });
    },
    voucherCancelled() {
      this.scanAgain();
    },
    allocateConfirmed() {
      this.trackReward.vendorId = this.useVendorToGetRewards.userID;
      this.trackReward.patronId = this.scannedUser.userID;
      this.trackReward.rewardId = this.reward.rewardId;
      // console.log("trackReward: " + this.trackReward.lastQrId);
      Comms.sendToServer(
        "POST",
        "updateTrack",
        this.trackReward,
        this.errors
      ).then((response) => {
        // console.log("reward response.data" + response.data);
        // console.log("reward response.status" + response.status);
        if (response.status == 200) {
          this.trackReward = JSON.parse(response.data);
          // console.log("Track count: " + this.trackReward.count);
          // console.log("Track goal: " + this.trackReward.goal);
          if (this.trackReward.count === this.trackReward.goal) {
            // goal reached.
            this.showGoalReached = true;
          } else {
            this.showGoalReached = false;
          }
          this.allocateDone = true;
        }
        // this.screenLoading = false;
      });
    },
    allocateCancelled() {
      this.scanAgain();
    },
    gotoAdmin() {
      if (this.$store.state.sytUser.type === "PATRON") {
        this.$router.push({ name: "Admin" });
      }
    },
    gotoRewards() {
      if (this.$store.state.sytUser.type === "VENDOR") {
        this.$router.push({ name: "Rewards" });
      }
    },
    loadReward(rewardId) {
      // console.log(this.$refs.allocateSection);
      // document.getElementById("allocateSection").scrollIntoView({behavior: 'smooth'});
      // console.log("Loading reward: " + rewardId);
      this.allocateDone = false;
      this.reward = Object.assign(
        {},
        this.rewards[this.rewards.findIndex((x) => x.rewardId === rewardId)]
      );
      this.errors = [];
    },
    onDecode(result) {
      if (this.$store.state.sytUser.type === "VENDOR") {
        this.result = result;
        console.log("this.result:" + this.result);
        if (this.result.length > 0) {
          this.turnCameraOff();
          this.showScanner = false;
          let scannedValues = result.split("|");
          //TODO check the date time in scannedValues[1]
          this.qrType = scannedValues[0];

          this.voucher.voucherId = "";
          this.voucher.trackId = "";
          this.voucher.patronId = "";
          this.voucher.rewardId = "";
          this.voucher.vendorId = "";
          this.voucher.createDate = "";
          this.voucher.redeemDate = "";
          this.voucher.status = "";
          this.voucher.vendorName = "";
          this.voucher.rewardName = "";
          this.voucher.actualReward = "";
          this.voucher.operatingAsVendorID = "";

          if (this.qrType === "n") {
            this.checkUser = true;
            // console.log("this.qrType:" + this.qrType);
            // this.scannedUser.userName = scannedValues[3];
            this.scannedUser.userID = scannedValues[2];
            this.trackReward.lastQrId = scannedValues[1];
            this.scannedUser.status = "QUERY";

            Comms.sendToServer(
              "POST",
              "getUserFromID",
              this.scannedUser,
              this.errors
            ).then((response) => {
              // console.log("user response.data" + response.data);
              // console.log("user response.status" + response.status);
              if (response.status === 200) {
                this.scannedUserComplete = JSON.parse(response.data);
                if (this.operatingAsYourSelf) {
                  this.useVendorToGetRewards = this.$store.state.sytUser;
                } else {
                  this.useVendorToGetRewards = this.actingAsVendor;
                }
                Comms.sendToServer(
                  "POST",
                  "getActiveRewards",
                  this.useVendorToGetRewards,
                  this.errors
                ).then((response) => {
                  // console.log("reward response.data" + response.data);
                  // console.log("reward response.status" + response.status);
                  if (response.status === 200) {
                    this.rewards = JSON.parse(response.data);
                    this.checksDone = true;
                    this.checkUser = false;
                  } else {
                    // rewards not created yet TODO display error 
                  }
                });
              } else {
                this.checkUser = false;
              }
              // this.screenLoading = false;
            });
          } else if (this.qrType === "v") {
            // console.log("this.operatingAsYourSelf: " + this.operatingAsYourSelf);
            if (this.operatingAsYourSelf) {
              this.voucher.operatingAsVendorID = this.$store.state.sytUser.userID;
            } else {
              this.voucher.operatingAsVendorID = this.actingAsVendor.userID;
            }
            // this.voucher.voucherId = scannedValues[2];
            // this.voucher.vendorId = scannedValues[3];

            this.voucher.voucherId = scannedValues[1];
            this.voucher.vendorId = scannedValues[2];
            // console.log("this.voucher.operatingAsVendorID: " + this.voucher.operatingAsVendorID);
            Comms.sendToServer(
              "POST",
              "getScannedVoucher",
              this.voucher,
              this.errors
            ).then((response) => {
              // console.log("voucher response.data" + response.data);
              // console.log("voucher response.status" + response.status);

              if (response.status === 200) {
                this.voucher = JSON.parse(response.data);
                this.checksDone = true;
              } else {
                // voucher not found or other error - TEST
                // error code handled in the Comms object
              }
            });
          } else {
            // invalid code
            this.errors.push(
              "Invlaid QR code. Only valid 'See you tomorrow' QR codes allowed."
            );
            // this.screenLoading = false;
          }
        }
      }
    },
    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    async onInit(promise) {
      this.screenLoading = true;
      this.qrType = "";
      try {
        await promise;
        this.screenLoading = false;
      } catch (error) {
        this.screenLoading = false;
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
        this.errors.push(this.error);
      }
    },
  },
  mounted: function () {
    if (!this.$store.state.authenticated) {
      this.$router.push({ name: "Home" });
    } else {
      this.operatingAsYourSelf = false;
      this.operatingAsAnotherVendor = false;

      this.voucher.voucherId = "";
      this.voucher.trackId = "";
      this.voucher.patronId = "";
      this.voucher.rewardId = "";
      this.voucher.vendorId = "";
      this.voucher.createDate = "";
      this.voucher.redeemDate = "";
      this.voucher.status = "";
      this.voucher.vendorName = "";
      this.voucher.rewardName = "";
      this.voucher.actualReward = "";
      this.voucher.operatingAsVendorID = "";
      console.log("this.$store.state.sytUser.operatingAsVendor: " + this.$store.state.sytUser.operatingAsVendor);
      if (this.$store.state.sytUser.operatingAsVendor == null) {
        this.$store.state.sytUser.operatingAsVendor = "";
      } else {
        // find the vendor
        if (this.$store.state.sytUser.operatingAsVendor.length > 0) {
          this.getVendorDelegated();
        }
      }
      if (this.$store.state.sytUser.vendorName == null) {
        this.$store.state.sytUser.vendorName = "";
      }
    }
  },
};
</script>

<style scoped>
.linkFormat {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
  text-decoration: underline;
  color: blue;
}

.qrScannerContainer {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrScannerSize {
  max-width: 300px;
  width: 100%;
}
</style>